import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, H3, TinyPopover } from '../../components/index.js';
import { NavLink } from 'react-router-dom';
import { navMenuToggle } from '../../actions/navigationActions.js';
import { isFactoryUser, isRslUser, isSupplierUser } from '../../reducers/authReducer.js';
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import * as Constants from '../../constants/header.js';
import { selectFullName, selectUserAccessToken, selectUserDetails, selectGroup } from '../../reducers/authReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import NotificationArea from '../../views/notifications/notificationArea/notificationArea.js'
import moment from 'moment';
import { mergeArrays } from '../../util/index.js';

class Header extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      status: true,
      isRead: false,
      litmosIsRead: false
    };
  }

  componentDidMount() {
    const { requestlistNotification } = this.props;
    const todayDate = moment.utc(new Date()).format('YYYY-MM-DD');
    requestlistNotification(todayDate);
  }
  notificationStatus = (status) => {
    const { notifications } = this.props;
    const { isRead,litmosIsRead } = this.props;
    const allUnreaded = notifications && notifications.filter(e => !e.isRead)
    const ids = allUnreaded && allUnreaded.length > 0 ? allUnreaded.map(e => e.id) : []
    if (!status && ids.length > 0 && !isRead && litmosIsRead) {
      this.setState({ isRead: true });
    }

    this.setState({ status: status });
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = new Date(moment(a).utc().format('DD-MMM-YYYY'));
    const utc2 = new Date(moment(b).utc().format('DD-MMM-YYYY'));
    return Math.floor((utc1 - utc2) / _MS_PER_DAY);
  }
  litmosNotificationStatus =(status)=>{
    this.setState({ litmosIsRead: status });
  }
  render() {
    const { isOpen, name, userToken, userExist, isErrorRoute, navMenuStateToggle, notifications, userGroup, isRslUser, allUnreaded, enableNav, isSupplierUser,userDetails,isFactoryUser } = this.props;
    const { status, litmosIsRead } = this.state;
        let unreadUserGroups = [];
    const unReaded = allUnreaded && 
    allUnreaded.filter(e => new Date(moment(e.startDate).utc().format('DD-MMM-YYYY')) <= new Date() 
    && 
    new Date(moment(e.endDate).utc().format('DD-MMM-YYYY')) >= new Date()
    )
    const unreadUserGroupArray = allUnreaded && allUnreaded.length > 0  && unReaded && unReaded.length > 0 && unReaded.map(e => e.userGroups)
    const unreadDateRanges = allUnreaded && 
    allUnreaded.map(e => new Date(moment(e.startDate).utc().format('DD-MMM-YYYY')) <= new Date() 
    && 
    new Date(moment(e.endDate).utc().format('DD-MMM-YYYY')) >= new Date())
    if (unreadUserGroupArray && unreadUserGroupArray.length > 0) {
      unreadUserGroups = mergeArrays(unreadUserGroupArray)
    }
    let supplierNotification = [];
    if((isSupplierUser || isFactoryUser) && userDetails.litmos !== null){
      const messageText = new Date(moment(userDetails.litmos.expiration).utc().format('DD-MMM-YYYY')) < new Date() ?
       "Your Training Certification has expired on "+userDetails.litmos.expiration+", please complete the training and re-submit your details through the Action Center. " 
       :"Your Training Certification will expire on "+userDetails.litmos.expiration+", please complete the training and re-submit your details through the Action Center before this expiration date."
      supplierNotification=[{
        "notificationId":"litmosId",
        "message": messageText,
        "isActive": true,
        "litmos": true,
        "userGroups": [
            "Suppliers","Factories"
        ],
        "isRead": litmosIsRead
      }]
      supplierNotification = this.dateDiffInDays(userDetails.litmos.expiration,new Date()) < 45 ? supplierNotification :[]
    }
    const notificationList = notifications !== null && notifications.concat(supplierNotification);
    return (
      <span>
        <iframe className="fake-iframe fake-iframe-header-height" title='fake' id='fake' src='about:blank' ></iframe>
        <Container section className={`header-container ncss-row ${isOpen ? 'hide-header' : ''}`}>
          <div className="ncss-col-sm-8">
            {userToken && userExist && !isErrorRoute && !enableNav ? (
              <button type="button" className="menu-btn inline-display ncss-btn fs16-sm" onClick={navMenuStateToggle}>
                <i className="g72-menu" />
              </button>
            ) : null}
            <NavLink to={userToken && userExist && !isErrorRoute ? '/' : '/error'} activeClassName="active">
              <H3 className="inline-display relative-position header-brand">
                <i className="g72-swoosh nav-brand fs22-sm menu-logo" />
                <span className="ncss-brand text-color-accent u-bold">{Constants.APPNAME}</span>
              </H3>
            </NavLink>
          </div>

          <div className="ncss-col-sm-4 u-bold">

            <span className="inline-display relative-position text-color-grey top-align ">
              <span>
                {userToken && userExist && !isErrorRoute ? (
                  <span className="button-notification">
                    <TinyPopover
                      activeStatus={
                        (!isRslUser && allUnreaded && allUnreaded.length > 0)
                        || (supplierNotification.length > 0 && !litmosIsRead ? true:false) || (isRslUser &&
                            unreadUserGroups.length > 0 && unreadUserGroups.includes('Rsl') &&
                            unreadDateRanges && unreadDateRanges.includes(true)) ? true : false } 
                      notificationStatus={this.notificationStatus}
                      children={(
                        <ul>
                          <NotificationArea isRslUser={isRslUser ? true : false} status={status} notifications={notificationList} userGroup={userGroup} litmosNotificationStatus={this.litmosNotificationStatus}  />
                        </ul>
                      )}
                    />
                  </span>
                ) : null}
              </span>


              {name ? (
                <span >
                  {Constants.LOGGEDTEXT} {name}{' '}
                </span>
              ) : null}
            </span>
          </div>


        </Container>

      </span>
    );
  }
}

Header.propTypes = {
  isOpen: PropTypes.bool,
  navMenuToggle: PropTypes.func,
  name: PropTypes.string,
  userToken: PropTypes.string,
  userExist: PropTypes.bool
};
export const mapStateToProps = state => {
  return {
    isOpen: selectIsMenuOpen(state),
    isRslUser: isRslUser(state),
    name: selectFullName(state),
    userToken: selectUserAccessToken(state),
    userExist: !!selectUserDetails(state).userId,
    userGroup: selectGroup(state),
    notifications: state.listNotification.notificationDetails,
    allUnreaded: state.listNotification.allUnreaded,
    isSupplierUser: isSupplierUser(state),
    userDetails: selectUserDetails(state),
    isFactoryUser:isFactoryUser(state)
  }
};
export const mapDispatchToProps = dispatch => ({
  navMenuStateToggle: () => {

    dispatch(navMenuToggle());

  },

  requestlistNotification: (info) => {
    dispatch(createServiceAction('listNotification', 'requested')(info));
  },
  updateNotificationStatus: (ids) => {
    dispatch(createServiceAction('readNotification', 'submitted')(ids));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
